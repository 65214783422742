import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import CoreuiVue from '@coreui/vue'
import CIcon from '@coreui/icons-vue'
import { iconsSet as icons } from '@/assets/icons'
import DocsCallout from '@/components/DocsCallout'
import DocsExample from '@/components/DocsExample'
import { Auth } from '@/services/Auth'
import { QuillEditor } from '@vueup/vue-quill'
import DropZone from 'dropzone-vue'

import '@vueup/vue-quill/dist/vue-quill.snow.css'
import 'dropzone-vue/dist/dropzone-vue.common.css'

const app = createApp(App)

app.use(store)
app.use(router)
app.use(CoreuiVue)
app.use(DropZone)
app.provide('icons', icons)
app.component('CIcon', CIcon)
app.component('DocsCallout', DocsCallout)
app.component('DocsExample', DocsExample)
// eslint-disable-next-line no-undef
app.component('QuillEditor', QuillEditor)

let auth
;(async () => {
  auth = new Auth(store, router)

  await auth.init()
})()

export { auth }

app.mount('#app')
